<script setup>

import SortWhereWash from "@/components/block/SortWhereWash";
import {onMounted, ref} from "vue";
import {dealerService} from "@/services/dealer.service";
import DealerCard from "@/components/block/DealerCard";

const title = 'Справочная информация о салонах, где используются наши товары'

const loadDialer = async () => dealerService.getList()

const dealers = ref([])

onMounted(async () => {
    dealers.value = await loadDialer()
})

</script>

<template>
    <main class="main">
      <section class="bread-crumbs section">
        <div class="container">
          <div class="bread-crumbs-list">
            <a class="bread-crumbs__link" href="/">Главная</a>
            <a class="bread-crumbs__link" href="javascript:void(0)">Где помыть</a>
          </div>
        </div>
      </section>
      <section class="where-to-buy section">
        <div class="container">
          <div class="section-header section-header_low-bottom-margin">
            <h2 class="section-header__title">{{ title }}</h2>
          </div>

          <SortWhereWash />

          <img class="where-to-buy__map" src="/img/map.png" alt="">
          <div class="where-to-buy-list">
            <div class="where-to-buy-list__item"
              v-for="dealer in dealers" :key="dealer.id"
            >
                <DealerCard :dealer="dealer" />
            </div>
          </div>
        </div>
      </section>
    </main>
</template>

<style scoped>

</style>
