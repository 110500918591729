import axios from "axios";
import tokenService from "@/services/token.service";
import {httpService} from "@/services/api/http.service";

const productService = {
  async getSegment(condition) {
    const token = await tokenService.getToken()

    try {
      const result = await axios(process.env.VUE_APP_API_URL + '/api/product/segment', {
        method: 'POST',
        data: condition,
        headers: {
          'Origin': process.env.VUE_APP_ORIGIN,
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Methods': 'GET,OPTIONS,POST',
          'Access-Control-Allow-Headers': 'Accept, Content-Type, Authorization, Access-Control-Allow-Origin',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })

      if (result.status === 200) {
        return result.data;
      }

    } catch (e) {
      return e.message
    }
  },

  async getSection(href) {
    const token = await tokenService.getToken()
    try {
      const result = await axios(process.env.VUE_APP_API_URL + '/api/product/section', {
        method: 'POST',
        data: {href: href},
        headers: {
          'Origin': process.env.VUE_APP_ORIGIN,
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Methods': 'GET,OPTIONS,POST',
          'Access-Control-Allow-Headers': 'Accept, Content-Type, Authorization, Access-Control-Allow-Origin',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      if (result.status === 200) {
        return result.data;
      }
      if(result.status === 404){
        return [];
      }

    } catch (e) {
      return []
    }
  },

  async getSearch(value) {
    const token = await tokenService.getToken()
    try {
      const result = await axios(process.env.VUE_APP_API_URL + '/api/product/search', {
        method: 'POST',
        data: {query: value},
        headers: {
          'Origin': process.env.VUE_APP_ORIGIN,
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Methods': 'GET,OPTIONS,POST',
          'Access-Control-Allow-Headers': 'Accept, Content-Type, Authorization, Access-Control-Allow-Origin',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      if (result.status === 200 || result.data.length >= 1) {
        return result.data;
      }

    } catch (e) {
      return []
    }
  },

  async getOneProduct(value) {
    const result = await httpService.get('product/one-view?href=' + value + '&expand=section')

    if (result.status === 200) {
      return result.data
    }
    return {}
  }
}

export default productService
