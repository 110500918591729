<script setup>
import { useBasketStore } from "@/stores/basketStore";
import ImageCard from "@/components/global/ImageCard";
import {productHelper} from "@/components/helpers/product.helper";
import LabelPromo from "@/components/global/LabelPromo";
import { defineProps } from 'vue';
import {createToaster} from "@meforma/vue-toaster";


const toaster = createToaster({ /* options */ });

const cart = useBasketStore();

const props = defineProps({
  product: {
    type: Object,
    default: {}
  }
})

function  addBasket()
{
  cart.addCart(props.product, 1)
  cart.calculationDiscount()
  toaster.success(`Товар добавлен в корзину`);
}

function  goDiscount()
{
  if(props.product.available_akcii === 0){
    return ''
  }
  const discount = Math.round(100 - (props.product.newprice * 100 / props.product.price))
  if (discount > 0 && props.product.available_akcii !== 0) {
    return 'скидка ' + discount + '% <br>' + props.product.price + '₽'
  }

}
function setVolume(item)
{
  return productHelper.volume(item)
}
function setPrice(item)
{
  if(item.available_akcii === 1){
    return item.newprice
  }
  return item.price
}

</script>

<template>
  <div class="products-grid__item-img-block">
    <ImageCard :image="{
      name:product.img,
      class:'products-grid__item-img',
      alt:product.name,
      href: product.href,
      id: 0
    }" />
    <LabelPromo :label="{name: '15%', color: 'green'}"/>
    <!-- @todo все скидки в лебел -->

  </div>
  <span class="products-grid__item-title"><span class="blue">{{ product.vendor_name }}</span> {{ product.name }} </span>
  <div class="products-grid__item-bottom-and-details">
    <span class="products-grid__item-details">
         {{ setVolume(product.attributesSets) }}  {{ product.unit }}
                      </span>
    <div class="products-grid__item-bottom">
      <a @click="addBasket" class="products-grid__item-bottom-basket" href="javascript:void(0)">{{ setPrice(product) }}
        ₽</a>
      <span
        class="products-grid__item-bottom-other" v-html="goDiscount()"></span>
    </div>
  </div>

</template>

<style scoped>

</style>
