<script setup>
import {useBasketStore} from "@/stores/basketStore";
import ImageCard from "@/components/global/ImageCard";
import BreadCrumbs from "@/components/block/BreadCrumbs";
import {computed, ref} from 'vue'
import LabelPromo from "@/components/global/LabelPromo";
import ProductViewCart from "@/components/elements/ProductViewCart";
import { useOrderStore } from "@/stores/orderStore";

const outLinks = [
  {name: "Корзина", href: "cart"}
]

const cart = useBasketStore()
const order = useOrderStore()
const checkDiscount = computed(async() => await cart.calculationDiscount())

function setValue(item, unit)
{
    if(item.name === 'Вес'){
        return item.value_int + unit;
    }
    return item.value;
}


function finalPrice(item)
{
  if(item.available_akcii === 1){
      return item.newprice
  }
  if(cart.discount.gallon.rate > 0){
      return item.price - (item.price / 100 * cart.discount.gallon.rate)
  }
  return item.price
}

function setCount(item, count)
{
   item.count += count
  cart.calculationDiscount()
}

function deleteProduct(item)
{
    cart.delete(item)
    cart.calculationDiscount()
}

</script>

<template>
  <main class="main">

    <BreadCrumbs :outLinks="outLinks"/>
    <section class="cart section">
      <div class="container">
        <div class="section-header section-header_flex-direction-column">
          <h2 class="section-header__title">Корзина</h2>
        </div>
        <div class="cart__inner">
          <div class="cart__top">
            <div class="cart__control">
              <div class="form__radio">
                <input v-model="order.order.typeDelivery" type="radio" id="delivery" name="value" value="3">
                <label class="form__radio-custom" for="delivery"></label>
                <label class="form__radio-label" for="delivery">Доставка</label>
              </div>
            </div>
            <div class="cart__control">
              <div class="form__radio">
                <input v-model="order.order.typeDelivery" value="1" type="radio" id="pickup" name="value">
                <label class="form__radio-custom" for="pickup"></label>
                <label class="form__radio-label" for="pickup">Самовывоз</label>
              </div>
            </div>
          </div>
          <p class="warning blue" v-show="false">
            <svg width="20" height="20">
              <use href="img/icons.svg#warning-circle" fill="#2ea6e5"></use>
            </svg>
            Принимаем заказы от 1 000 ₽ для доставки, самовывоз без ограничений.
          </p>

          <div class="products-list">
            <div class="products-list__item"
                 v-for="item in cart.cart"
                 :key="item.id"
            >
              <ProductViewCart :product="item" />

            </div>

          </div>
          <div class="cart__bottom">
            <div class="cart__bottom-btn-block">
              <router-link to="/checkout" class="btn btn_blue">Оформить доставку</router-link>
              <p class="description">Дата и стоимость доставки определяются при оформлении заказа</p>
            </div>
            <div class="cart__bottom-info">
              <ul class="cart__bottom-info-details">
                <li>
                  <span>Товары  {{ cart.countCartItems }}</span>
                  <span>{{ cart.totalAmount }} ₽</span>
                </li>
              </ul>
              <div class="cart__bottom-info-total">
                <span class="text">Итого</span>
                <span class="price"> {{ cart.totalAmount }} ₽</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>


<style scoped lang="scss">
.cart .products-list__item-controls-child:nth-child(2){
    justify-content: flex-end;
}
</style>
