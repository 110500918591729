<script setup>

  import {contentService} from "@/services/content.service";
  import {onMounted, ref} from "vue";
  import BreadCrumbs from "@/components/block/BreadCrumbs.vue";

  const loadContent = async () => contentService.getSectionContent('contact')

  const content = ref('')

  onMounted(async () => {
      content.value = await loadContent()
  })

  const outLinks = ref([
    {
      name: 'Контакты',
      href: '/contact'
    }
  ])
</script>

<template>
  <main class="main">
    <BreadCrumbs :outLinks="outLinks" />
  <section class="container">
    <div class="map">
    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A91bb12754ddb2e92ee78d722ea52c292853d9c458b6818d4818ca79f5b608e33&amp;source=constructor" width="100%" height="100%" frameborder="0"></iframe>
    </div>
    <div class="block" v-html="content.content">

      </div>
  </section>
  </main>
</template>



<style scoped>
  .map {
    height: 250px;
  }
</style>
