<script setup>
import {contentService} from "@/services/content.service";
import {onMounted, ref} from 'vue'
import BreadCrumbs from "@/components/block/BreadCrumbs";

const loadContent = async () => await contentService.getSectionContent('partner')

const content = ref('')

onMounted(async () => {
    content.value = await loadContent()
})

const outLinks = [
  { name: 'Партнерам', href: '/partner' }
];

</script>

<template>
  <BreadCrumbs :out-links="outLinks" />
  <section class="content">
    <div class="container">
      <div class="alert alert-success">
        <h3>Текст для дилеров и партнеров</h3>

      </div>

      <h2>Мы рады и все такое</h2>
      <h3>Варианты сотрудничества:</h3>
      <div class="row">
        <div class="col-sm-6">
          <div class="card" style="width: 38rem;">

            <div class="card-body">
              Блок с информацией
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card" style="width: 38rem;">
            Второй блок с информацией
          </div>
        </div>
      </div>
    </div>

  </section>


</template>



<style scoped lang="scss">
  .row{
    display: flex;
  }

</style>
