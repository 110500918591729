<script setup>
import {useBasketStore} from "@/stores/basketStore";
import ModalProduct from "@/components/block/ModalProduct";
import ImageCard from "@/components/global/ImageCard";
import LabelPromo from "@/components/global/LabelPromo";
import {computed, defineProps, onMounted, ref} from 'vue';
import {createToaster} from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });
import { productHelper } from "@/components/helpers/product.helper";

const cart = useBasketStore()

const  minPriceProduct =  ref(0)
const discountProduct =  ref(0)
const currency = '₽'
const openModal =  ref(false)
const  countModify =  ref(0)

const currentPrice = ref({
  price: 0,
  basicPrice: 0,
  discount: 0
})


const availableLabel = ref({
  visible: false
})

const props = defineProps({
  product: Object,
  sectionProduct: Object
})

onMounted(() => {
  minPriceProduct.value = minPrice()
  countModify.value = props.product.price.length
  availableLabel.value = {
    name: getName(),
    color: 'basic',
    visible: getVisibleLabel(),
    class: 'products-grid__item-status'
  }
  if(props.product.price.length === 1){
    currentPrice.value = productHelper.discountPrice(props.product.price[0])
  }

  if(props.product.price.length > 1){
    currentPrice.value = productHelper.discountPrice(props.product.price[0])
  }
})

function getName()
{
   let q = props.product.price.find(el => el.available_akcii === 1)
    if(q !== undefined){
        return 'Акция'
    }

    return null;
}

function getVisibleLabel()
{
  let q = props.product.price.find(el => el.available_akcii === 1)
  if(q !== undefined){
    return true
  }
  return false
}

function addBasket() {
  if(countModify.value === 0){
    toaster.error('Товара нет в продаже')
  }

  if (countModify.value > 1) {
    openModal.value = true
  }
  if (countModify.value === 1) {
    cart.addCart(props.product.price[0], 1)
    toaster.success(`Товар добавлен в корзину`);
  }
}

function  minPrice() {
  if (props.product.price.length === 1) {
    return props.product.price[0].price
  }
  if (props.product.price.length === 0) {
    return 'Нет в продаже'
  }
 // console.dir(props.product.price)
  // если акция. // если шампунь.
  if([4,5,6].includes(props.sectionProduct.did)){
      let gallon = props.product.price.reduce((accEl, curEl) => {
        if(accEl === 0){
          accEl = curEl
        }
          let weight = curEl.attributesSets.find((item) => item.name == 'Вес');
          let referenceWeight = accEl.attributesSets.find((item) => item.name == 'Вес')
          if(weight.value_int < 19 && weight.value_int >= referenceWeight.value_int){
              return curEl
          }else{
            return accEl
          }
      }, 0)
    if(gallon !== undefined){
      return  gallon.available_akcii === 1? gallon.newprice: gallon.price
    }

  }


  const final = props.product.price.map(item => {
      if(item.available_akcii === 1){
        return parseFloat(item.newprice)
      }
      return parseFloat(item.price)
  })
  const finalPrice = Math.min.apply(null, final)
  return finalPrice
}
function closeModal() {
  openModal.value = false
}

</script>

<template>
  <div class="products-grid__item-img-block">
    <ImageCard :image="{
      name: product.img,
      alt: product.name,
      class:'products-grid__item-img',
      id: product.id,
      href: product.href
    }"/>
    <LabelPromo :label="availableLabel" />
  </div>
  <span class="products-grid__item-title">
    <span class="blue">{{ product.vendor_name }}</span> {{ product.name }}
  </span>
  <span class="products-grid__item-title-import">{{product.import_name}}</span>
  <div class="products-grid__item-bottom-and-details">
    <span style="font-size: 10px"  v-for="tag in product.tags" :key="tag.id">{{ tag.name }}</span>
    <span class="products-grid__item-details" v-if="(countModify > 1)">
        <img src="/img/icons/colors.svg" alt=""> {{ countModify }} варианта
    </span>
    <div class="products-grid__item-bottom" v-if="(countModify > 1)">
      <a @click="addBasket"
         class="products-grid__item-bottom-basket"
         href="javascript:void(0)">
        {{ minPriceProduct + ' ' + currency }}
      </a>
      <span v-if="currentPrice.discount !== 0"
            class="products-grid__item-bottom-other">{{ currentPrice.discount }}% <br /><s>{{ currentPrice.basicPrice }}</s></span>
    </div>
    <div class="products-grid__item-bottom" v-else>
      <a @click="addBasket" v-if="currentPrice.price !== 0"
         class="products-grid__item-bottom-basket"
         href="javascript:void(0)">
        {{  currentPrice.price + ' ' + currency }}
      </a>
      <a @click="addBasket" v-else
         class="products-grid__item-bottom-basket"
         href="javascript:void(0)">
        Нет в продаже
      </a>
      <span v-if="currentPrice.discount !== 0"
        class="products-grid__item-bottom-other">{{ currentPrice.discount }}% <br /><s>{{ currentPrice.basicPrice }}</s></span>
    </div>
  </div>
  <ModalProduct :show="openModal" @close="closeModal" :product="product"/>
</template>


<style scoped lang="scss">
  .products-grid__item-title-import{
      font-size: 14px;
  }
</style>
