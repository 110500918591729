import axios from "axios";
import tokenService from "@/services/token.service";

export const advertService = {

  async getAllList() {

    const section = [
      {
        name: 'video',
        count: 1
      },
      {
        name: 'article',
        count: 1
      },
      {
        name: 'partner',
        count: 1,
      },
      {
        name: 'promotion',
        count: 1
      }
    ]

    const list = [
      {
        id: 1,
        href: '/video',
        img: '/img/recommended-articles/ras1.png',
        titleSection: 'Видео',
        name: 'Баннер на раздел сайта',
        autor: '',
        icons: '/img/icons/timer.svg',
        iconsTime: '20 мин'
      },
      {
        id: 2,
        href: '/pay',
        img: '/img/recommended-articles/ras2.png',
        titleSection: 'Раздел сайта',
        name: 'Доставка или любой другой',
        autor: '',
        icons: '/img/icons/arrow-right-big-black.svg',
        iconsTime: ''
      },
      {
        id: 3,
        href: '/question',
        img: '/img/recommended-articles/ras3.png',
        titleSection: 'Уточни, если не нашел',
        name: 'Рубрика вопрос-ответ',
        autor: '',
        icons: '/img/icons/arrow-right-big-black.svg',
        iconsTime: ''
      },
      {
        id: 4,
        href: '/article',
        img: '/img/recommended-articles/ras4.png',
        titleSection: 'Статьи',
        name: 'Познавательная информация',
        autor: '',
        icons: '/img/icons/timer.svg',
        iconsTime: '5 мин'
      }
    ]

    return list
  },

  async getVideo(count) {

    const token = await tokenService.getToken()
    const result = await axios(process.env.VUE_APP_API_URL + '/api/video/search', {
      method: 'POST',
      data: {count: count},
      headers: {
        'Origin': process.env.VUE_APP_ORIGIN,
        'User-Agent': ' curl/7.51.0',
        'Access-Control-Allow-Methods': 'GET,OPTIONS,POST',
        'Access-Control-Allow-Headers': 'Accept, Content-Type, Authorization, Access-Control-Allow-Origin',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    if (result.status === 200) {
      return await this.transformVideo(result.data);
    }
    return []
  },

  transformVideo(result) {
    const ar = result.map((el) => {
      return {
        href: el.id,
        img: el.img_screen,
        titleSection: 'Видеоуроки',
        name: el.name,
        autor: 'Соколова',
        icons: 'timer.svg',
        iconsTime: '10'
      }
    })
    return ar
  },

  async getArticle(count) {

    const token = await tokenService.getToken()
    const result = await axios(process.env.VUE_APP_API_URL + '/api/article/search', {
      method: 'POST',
      data: {count: count},
      headers: {
        'Origin': process.env.VUE_APP_ORIGIN,
        'User-Agent': ' curl/7.51.0',
        'Access-Control-Allow-Methods': 'GET,OPTIONS,POST',
        'Access-Control-Allow-Headers': 'Accept, Content-Type, Authorization, Access-Control-Allow-Origin',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    if (result.status === 200) {
      return await this.transformVideo(result.data);
    }
    return []
  },

  transformArticle(result) {
    const ar = result.map((el) => {
      return {
        href: el.article_id,
        img: el.imgige,
        titleSection: 'Статьи',
        name: el.name,
        autor: 'Петрова',
        icons: 'timer.svg',
        iconsTime: '10'
      }
    })
    return ar
  },

  async stock() {
    const promises = await Promise.all(section.map(async (el) => {
      let item = ''
      if (el.name === 'video') {
        item = await this.getVideo(el.count)
        return this.transformVideo(item)
      }
      if (el.name === 'article') {
        item = await this.getArticle(el.count)
        return this.transformArticle(item)
      }
      if (el.name === 'promotion') {

      }
      if (el.name === 'partner') {

      }
    }));
  }

}
