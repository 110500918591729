<script setup>

import BreadCrumbs from "@/components/block/BreadCrumbs";
import newsService from "@/services/news.service";
import {onMounted, ref, defineProps } from "vue";
import NewsCard from "@/components/block/NewsCard";
import { useRoute } from "vue-router";

const router = useRoute()

const outLinks = [
  {name:'Новости',href:'news'}
]

const props = defineProps({
  id: {
    type: Number,
  }
})

const title = 'Новости Компании'

const loadNews = async () => newsService.list()
const news = ref([])

onMounted(async () => {
    news.value = await loadNews()
})

const openNews = ref(0)

function toogleNews(id)
{
    if(openNews.value === id){
        openNews.value = 0
    }else{
      openNews.value = id
    }

}

</script>

<template>
    <main class="main">
      <BreadCrumbs :outLinks="outLinks" />

      <section class="section">
        <div class="container">
          <div class="section-header section-header_low-bottom-margin">
            <h2 class="section-header__title">{{ title }}</h2>
          </div>

          <div class="faq-list faq-list_dropdown">
            <div class="faq-list__item"
              v-for="item in news" :key="item.id"
              :class="{active: openNews === item.id}"
            >
                <NewsCard :news="item"  @readId="toogleNews(item.id)" />
            </div>
          </div>
        </div>
      </section>
<!--
      <div class="pagination">
        <a class="pagination__item pagination__item_prev" href="">Назад</a>
        <a class="pagination__item active" href="">1</a>
        <a class="pagination__item" href="">2</a>
        <a class="pagination__item" href="">3</a>
        <a class="pagination__item pagination__item_next" href="">Вперед</a>
      </div>	-->
    </main>
</template>

<style scoped>

</style>
