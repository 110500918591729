<script setup>
import productService from "@/services/product.service";
import ProductCard from "@/components/elements/ProductCard";
import { helpService } from "@/services/help.service";
import TagsList from "@/components/block/TagsList";
import Paginate from "vuejs-paginate-next";
import { ref, onMounted, computed, defineEmits, watch } from "vue";
import { useRoute } from 'vue-router'
import ProductFilter from "@/components/block/ProductFilter";

const route = useRoute()

const pageSize = 30

const pageCount = ref(0)

const currentPage = ref(1)


const loadSection = async () => await helpService.getSection(route.params.href)
const loadBrand = async () => await helpService.getBrandSection(route.params.href)
const loadProduct = async () => await productService.getSection(route.params.href)

const product = ref([])
const section = ref({})
const brand = ref([])
const resetFilter = ref(false)
const filterProduct = ref([])
const hideBlock = ref(true)

onMounted(async () => {

  section.value = await loadSection()
  brand.value = await loadBrand()
  product.value = await loadProduct()
  await emitSection()
  pageCount.value = Math.ceil(product.value.length / pageSize)
})


const viewProduct = computed(() => {
  if (filterProduct.value.length >= 1) {
    return finalProduct()
  }
  return product.value.filter((item, key) => key <= 31 * currentPage.value && key >= 0  )  // 0  31
})

const emits = defineEmits([
   'section'
])

watch(() => route.path, async (newVal) => {
  product.value = []
  pageCount.value = Math.ceil(product.value.length / pageSize)
  section.value = await loadSection()
  brand.value = await loadBrand()
  product.value = await loadProduct()
  await  emitSection()
});

async function emitSection()
{
  emits('section', section)
}

function reset()
{
    resetFilter.value = true
    filterProduct.value = []
}


function finalProduct()
{
  return product.value.filter((el) => {
    return el.tags.find((item) => {
      return filterProduct.value.includes(item.id)
    }, filterProduct.value)
  }, filterProduct.value)
}

function  hideBlockTag()
{
  hideBlock.value = false
}

function setFilter(data)
{
   filterProduct.value = data
}

function listingCatalog(pageNum)
{
    currentPage.value = pageNum
}

</script>

<template>
    <section class="products section">
      <div class="container">
        <div class="section-header section-header_low-bottom-margin">
          <h2 class="section-header__title">{{ section.name }}</h2>
        </div>

        <div class="tags tags_low-bottom-margin" v-if="hideBlock">
          <div class="tags__info">
            <span class="tags__info-label">Часто ищут:</span>
            <span class="tags__info-clear" @click="reset">очистить</span>
          </div>
          <TagsList @hideTags="hideBlockTag" @checkedTag="setFilter" />
        </div>
      <ProductFilter />
        <div class="products-grid" v-if="viewProduct.length > 0">
          <div class="products-grid__item"
            v-for="item in viewProduct"
            :key="product.id"
          >
            <ProductCard :product="item"  :sectionProduct="section" />
          </div>
        </div>
        <div class="products-grid" v-else>
          <div class="products-grid__item2">
            Товаров в данном разделе пока нет.
          </div>
        </div>
      </div>
    </section>
  <Paginate v-if="product.length > pageSize"
    :page-count="pageCount"
    :click-handler="listingCatalog"
    :prev-text="'Назад'"
    :next-text="'Вперед'"
    :container-class="'pagination'"
    :page-class="'pagination__item'"
    :prev-class="'pagination__item pagination__item_prev'"
    :next-class="'pagination__item pagination__item_next'"
  >
  </Paginate>
</template>

<style scoped>

</style>
