<script setup>
import {ref, computed, onMounted, watch} from "vue";
import {useBasketStore} from "@/stores/basketStore";
import {useSearchStore} from "@/stores/searchStore";
import {useMediaQuery} from '@vueuse/core';
import {createToaster} from '@meforma/vue-toaster';
import {useRouter} from 'vue-router'
import {useNavStore} from "@/stores/navStore";
import productService from "@/services/product.service";
import navService from "@/services/nav.service";

const router = useRouter()
const basketStore = useBasketStore()
const searchStore = useSearchStore()

const navStore = useNavStore()

const basicNav = computed(() =>  navStore.getBasic)
const catalogNav = computed(() => navStore.getCatalog)
const otherNav = computed(() =>  navStore.getOther)

/*const basicNav = ref([])
const catalogNav = ref([])
const otherNav = ref([])*/

/*
const loadNav = async () => await navService.basicBar()
const loadCatalog = async () => await navService.catalogBar()
const loadOther = async () => await navService.otherBar()

onMounted(async () => {
  basicNav.value = await loadNav()
  catalogNav.value = await loadCatalog()
  otherNav.value = await loadOther()
})
*/

const visibleMenu = ref(false)

const showMobileMenu = useMediaQuery('(max-width: 991px)');

const showCatalogMenu = ref(false)

function toggleMenu() {
  visibleMenu.value = !visibleMenu.value
  showCatalogMenu.value = false
}

function toggleCatalog() {
  showCatalogMenu.value = !showCatalogMenu.value
  visibleMenu.value = false
}

function close(){
  showCatalogMenu.value = false
  visibleMenu.value = false
}

const showSearchInput = ref(false)

const query = ref('')
const toaster = createToaster({
  position: "top-right",
  duration: 2000,
})

function goSearch() {

  if (query.value.length === 0) {
    toaster.error('Не введен поисковый запрос')
    return
  }
  searchStore.saveQuery(query.value)
  router.push({ name: 'search' })
}

</script>

<template>
  <header class="header">
    <div v-if="!showMobileMenu" class="header__top">
      <div class="container header__container header__top-container">
        <ul class="menu">
          <li v-for="itemMenu in basicNav"
              :key="itemMenu.sort"
              class="menu__item"
          >
            <router-link class="menu__item-link" :to="{ name: itemMenu.href}">
              {{ itemMenu.name }}
            </router-link>
          </li>
        </ul>
        <a class="header__top-tel" href="tel:+70000000000">+7 (903) 000-0000</a>
        <button
          class="header__top-burger"
          :class="{'active':visibleMenu}"
          @click="toggleMenu"
        ></button>
        <ul class="header__top-dropdown-list" :class="{'visibleMenu':visibleMenu}">
          <li v-for="itemMenu in otherNav"
              :key="itemMenu.sort"
          >
            <router-link :to="{ name: itemMenu.href }">
              {{ itemMenu.name }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="!showMobileMenu"
         class="header__bottom"
         :class="{'header__bottom_search-active': showSearchInput}"
    >
      <div class="container header__container header__bottom-container">
        <!--            header bottom-->
        <router-link :to="{ name: 'home' }" class="header__bottom-logo" exact>
          <img src="/img/logo.svg" alt="Лого">
        </router-link>
        <ul class="menu">
          <li
            v-for="section in catalogNav"
            :key="section.sort"
            class="menu__item">
            <router-link :to="{ name: 'catalog', params: {href: section.href}}">
              {{ section.name }}
            </router-link>
          </li>
        </ul>

        <div class="right">
          <form class="search" @focusin="showSearchInput=true" @submit.prevent="goSearch">
            <i v-show="showSearchInput" title="Закрыть" @click="showSearchInput=false">X</i>
            <input v-model="query" type="text" placeholder="Поиск">
            <img src="/img/icons/search.svg" v-show="showSearchInput" @click="goSearch" title="Найти">
          </form>
<!--          <router-link to="/news" class="header__bottom-profile"></router-link>-->

          <router-link class="header__bottom-basket" to="/cart">
            {{ basketStore.countCartItems }}
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="showMobileMenu" class="header__top_mob">
      <div class="container">
<!--        <a class="header__top-profile" href="/"></a>-->
        <a class="header__top-basket" href="/cart">{{ basketStore.countCartItems }}</a>
      </div>
    </div>
    <div v-if="showMobileMenu" class="header__bottom_mob">
      <div class="container">
        <router-link to="/" class="header__bottom-logo">
          <img src="/img/logo.svg" alt="Лого">
        </router-link>
        <span class="menu-title" @click="toggleCatalog">Каталог</span>
        <button @click="toggleMenu" class="header__bottom-burger"></button>
      </div>
      <div id="header__bottom_mob-catalog" class="header__bottom_mob-menu"
           :class="{active: showCatalogMenu}">
        <div class="container">
          <ul class="dropdown-menu">
            <li class="dropdown-menu__item"
                v-for="itemMobMenu in catalogNav"
                :key="itemMobMenu.sort"
            >
              <span class="dropdown-menu__title">
                <router-link :to="{ name: 'catalog', params: {href: itemMobMenu.href}}" @click="close">
                {{ itemMobMenu.name }}
                </router-link>
              </span>
            </li>
          </ul>
          <form class="search" action="/search">
            <input type="text" placeholder="Поиск">
          </form>
        </div>
      </div>
      <div id="header__bottom_mob-menu" class="header__bottom_mob-menu"
       :class="{active:visibleMenu}" >
        <div class="container">
          <router-link @click="close"  class=" btn btn_outline-blue" to="/question">Задать вопрос ветеринару</router-link>
          <ul class="menu menu_center">
            <li class="menu__item" v-for="itemMenu in basicNav" :key="itemMenu.sort">
              <router-link @click="close" class="menu__link" :to="{ name: itemMenu.href }">
                {{ itemMenu.name }}
              </router-link>
            </li>
            <li class="menu__item" v-for="itemMenu in otherNav" :key="itemMenu.sort">
              <router-link @click="close" class="menu__link" :to="{ name: itemMenu.href}">
                {{ itemMenu.name }}
              </router-link>
            </li>
          </ul>
          <form class="search" action="/search">
            <input type="text" placeholder="Поиск">
          </form>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
.visibleMenu {
  display: block
}

.active {
  display: block;
}

.menu__item a {
  text-decoration: none;
}

.header a {
  text-decoration: none;
}

.header__top-tel{
  border-radius: 30px 3px 30px 3px;
}

.header__bottom_search-active .search::before {
  mask-image: none;
  background-color: #ffffff;
  width: 0;
}
</style>
