<script setup>
import {useBasketStore} from "@/stores/basketStore";
import Footer from "@/layouts/Footer";
import Header from "@/layouts/Header";
import {onMounted} from "vue";

const cart = useBasketStore()

</script>

<template>
  <div class="wrapper">
    <Suspense>
    <Header/>
    </Suspense>
    <Suspense>
      <template #default>
    <router-view/>
      </template>
      <template #fallback><div>Loading.........</div></template>
    </Suspense>
    <Suspense>
    <Footer/>
    </Suspense>

  </div>
</template>

<style lang="scss">

</style>
