<script setup>

import newsService from "@/services/news.service";
import NewsBlock from "@/components/elements/NewsBlock";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper';
import 'swiper/css';
import { paramsHelper } from "@/components/helpers/params.helper";

import { useNavStore } from "@/stores/navStore";
import {computed, ref} from "vue";

const onSwiper = (swiper) => {}
const onSlideChange = () => {}
const navStore = useNavStore()

const listNews = await newsService.search({limit: 6})

const basicNav = computed(() =>  navStore.getBasic)
const catalogNav = computed(() => navStore.getCatalog)
const otherNav = computed(() =>  navStore.getOther)

const swiperOptions = {
  breakpoints: {
    360: {
      slidesPerView: 1,
    },
    766: {
      slidesPerView: 2,
    },
    993: {
      slidesPerView: 4,
    }
  }
}

const showCatalog = ref(true)
const showBasic = ref(true)
const showOther = ref(true)
const showPay = ref(false)

function toggleCatalog()
{
  showBasic.value = false
  showOther.value = false
  showCatalog.value = !showCatalog.value
}
function toggleBasic()
{
  showCatalog.value = false
  showOther.value = false
  showBasic.value = !showBasic.value
}
function toggleOther()
{
  showCatalog.value = false
  showBasic.value = false
  showOther.value = !showOther.value
}
function togglePay()
{
  showCatalog.value = false
  showBasic.value = false
  showOther.value = false
  showPay.value = !showPay.value
}

</script>

<template>
  <footer class="footer">
    <div class="footer__top">
      <div class="footer__top-container container">
        <section class="section">
          <div class="container">
            <div class="section-header">
              <h2 class="section-header__title">Новости</h2>
              <div class="section-header__swiper-buttons news-products-slider">
              </div>
            </div>
            <swiper
              :slides-per-view="4"
              :modules="modules"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
              :breakpoints="swiperOptions.breakpoints"
              class="ras2-list products-grid_slider news-products-slider"
            >
              <swiper-slide
                v-for="item in listNews"
                :key="item.id"
                class="ras2-list__item swiper-slide"
              >
                <router-link :to="{ name: 'news', params: { id: item.id}}"
                >
                 <NewsBlock :oneNews="item" />
                </router-link>
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination"></div>
          </div>
        </section>
      </div>
    </div>
    <div class="footer__middle">
      <div class="container footer__middle-container">
        <div class="footer__widgets">
          <div class="footer__widget">
            <p class="footer__widget-title" @click="toggleCatalog" >Каталог</p>
            <ul class="footer-menu" :class="{active: showCatalog}">
              <li v-for="itemCatalog in catalogNav" :key="itemCatalog" class="footer-menu__item">
                <router-link :to="{name:'catalog', params:{href: itemCatalog.href}}" class="footer-menu__item-link">
                {{ itemCatalog.name }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="footer__widget">
            <p class="footer__widget-title" @click="toggleOther" >Деятельность</p>
            <ul class="footer-menu" :class="{active: showOther}">
              <li v-for="itemOther in otherNav" class="footer-menu__item" :key="itemOther.id">
                <router-link class="footer-menu__item-link" :to="{name: itemOther.href}">
                  {{ itemOther.name }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="footer__widget">
            <p class="footer__widget-title" @click="toggleBasic">Покупателям</p>
            <ul class="footer-menu" :class="{active: showBasic}">
              <li v-for="itemBasic in basicNav" :key="itemBasic.id"
                  class="footer-menu__item">
                <router-link class="footer-menu__item-link" :to="{name: itemBasic.href}">{{ itemBasic.name }}</router-link>
              </li>
            </ul>
          </div>

          <div class="footer__widget">
            <p class="footer__widget-title" @click="togglePay">Способы оплаты</p>
            <div class="cards-list" :class="{active: showPay}">
              <img src="/img/logos/visa.png" alt="VISA">
              <img src="/img/logos/mastercard.png" alt="MasterCard">
              <img src="/img/logos/mir.png" alt="МИР">
            </div>
          </div>
        </div>
        <div class="footer__subscribe">
            <ul class="address-list">
            <li class="address-list__item">Офис: {{ paramsHelper.addressOffice }}</li>
            <li class="address-list__item">Склад: {{ paramsHelper.addressStock }}</li>
              <li class="address-list__item">Email: {{ paramsHelper.email }}</li>
          </ul>
          <div class="social">
            <div class="social__inner">
              <div class="social-list">
                <a class="social-list__item vk" :href="paramsHelper.vk" target="_blank" title="Вконтакте"></a>
                <a class="social-list__item dz" :href="paramsHelper.dzen" target="_blank" title="Яндекс.Дзен"></a>
                <a class="social-list__item tg" :href="paramsHelper.tm" target="_blank" title="Telegram"></a>
                <a class="social-list__item yt" :href="paramsHelper.youtube" target="_blank" title="YouTube"></a>
              </div>
            </div>
            <img class="social__img-cut" src="/img/cut.png" width="140" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="container">
        <ul class="menu">
          <li class="menu__item"><span>© 2024 "Carcados"</span></li>
          <li class="menu__item"><a class="menu__item-link" href="#">Пользовательское соглашение</a></li>
          <li class="menu__item"><a class="menu__item-link" href="#">Политика конфиденциальности</a></li>
        </ul>
      </div>
    </div>
  </footer>
</template>


<style scoped lang="scss">
  .active{
      display: block;
  }
</style>
