import axios from "axios";
import tokenService from "./token.service";

const sliderService = {

    async getAll() {
      const token = await tokenService.getToken();
        try{
            const result = await axios(process.env.VUE_APP_API_URL + '/api/slider/index', {
                method: 'GET',
              headers: {
                'Origin': process.env.VUE_APP_ORIGIN,
                'User-Agent': ' curl/7.51.0',
                'Access-Control-Allow-Methods': 'GET,OPTIONS,POST',
                'Access-Control-Allow-Headers': 'Accept, Content-Type, Authorization, Access-Control-Allow-Origin',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${token}`
              }
            })
          if(result.status === 200){
            return result.data;
          }

        }catch (e){
            return e.message
        }
    }
}

export default sliderService
