<script setup>

import BreadCrumbs from "@/components/block/BreadCrumbs";
import {onMounted, ref} from 'vue'
import videoService from "@/services/api/video.service";
const title = 'Видеораздел'
const outLinks = [
  {
    name: 'Видео', href: '/video'
  }
]

const loadVideo = async () => videoService.list(0)

const videos = ref([])

onMounted(async () => {
    videos.value = await loadVideo()
})

</script>

<template>
    <main class="main">

     <BreadCrumbs :out-links="outLinks" />

      <section class="videos section">
        <div class="container">
          <div class="section-header section-header_low-bottom-margin">
            <h2 class="section-header__title">{{ title }}</h2>
          </div>

          <div class="videos-list">
            <div class="videos-list__item"  v-for="video in videos" :key="video.id">
              <i v-html="video.href_youtube"></i>
              <p class="videos-list__item-title">{{ video.name }}</p>
            </div>
          </div>
        </div>
      </section>

<!--      <div class="pagination">
        <a class="pagination__item pagination__item_prev" href="">Назад</a>
        <a class="pagination__item active" href="">1</a>
        <a class="pagination__item" href="">2</a>
        <a class="pagination__item" href="">3</a>
        <a class="pagination__item pagination__item_next" href="">Вперед</a>
      </div>-->		</main>
</template>



<style scoped>

</style>
